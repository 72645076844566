* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  cursor: default;
}

.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  /* HIDE SSCROLLBAR*/
  /* Firefox */
  /* scrollbar-width: none;
  -ms-overflow-style: none; */

  /* IF CHANGING DEFAULT FONT CHANGE CLASS BELOW TOO */
  font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
}
.AppDefaultFont {
  /* IF CHANGING DEFAULT FONT CHANGE ABOVE IN APP CLASS TOO */
  font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
}
/* ::-webkit-scrollbar {
  width: 0;
  height: 0;
} */
/* THE NEW WAY OF STYLING THE SCROLLBAR
IN CASE WANT TO SHOW IT IN PLACES 
BASICALLY, YOU USE THE WEBKIT PREFIX
AND THE NEW STANDARD scrollbar-width and scrollbar-color
*/

/* 
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
::-webkit-scrollbar {
  width: 11px;
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
} */

/*BEG FONT SIZE SETTINGS*/

.AppFontSizeExtraSmall {
  font-size: calc(7px + 1vmin);
}
.AppFontSizeSmall {
  font-size: calc(10px + 1vmin);
}
.AppFontSizeNormal {
  font-size: calc(10px + 2vmin);
}
.App input {
  font-size: calc(10px + 2vmin);
  outline: none;
  box-shadow: none;
  padding: 0.25em;
  margin: 0.5em 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.App textarea {
  font-size: calc(10px + 2vmin);
  width: 100%;
  padding: 0.25em;
  margin: 0.5em 0;
  outline: none;
  box-shadow: none;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.1);
}
select {
  -webkit-appearance: none;
}
.App select {
  /* CAREFUL ADJUSTING MAKING ADJUSTMENTS HERE
  AS THIS WILL AFFECT POSITIONING IN HIDDEN SELECTS
  THAT ARE POSITIONED ABSOLUTE */
  font-size: 1.3em;
  padding: 0.25em;
  width: 100%;
  border-radius: 5px;
  white-space: nowrap;
  /* color: black;
  background-color: rgba(0, 0, 0, 0.1); */
}

::placeholder {
  color: gray;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gray;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: gray;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: gray;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: gray;
}
.AppFontSizeBig1 {
  font-size: 1.5em;
}
.AppFontSizeBig2 {
  font-size: 2em;
}
/*END FONT SIZE SETTINGS*/

.inputWrap {
  margin: 0.5em 0;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.appTitleText {
  transition: all 0.1s linear 0.2s;
}

a {
  text-decoration: none;
  color: white;
}
.App-link {
  color: #61dafb;
}
input {
  /* -webkit-appearance: none; */
  border: none;
}
.App input:focus {
  box-shadow: none;
}
.App select:focus {
  box-shadow: none;
  outline: none;
}

textarea {
  border-radius: 0;
  -webkit-appearance: none;
  border: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  /* -webkit-text-fill-color: black; */
  -webkit-box-shadow: 0 0 0px 1000px #e5e5e5 inset;
  transition: all 0.1s linear 0s;
}

button {
  transition: all 0.3s;
  border: none;
  color: #fff;
  margin: 5px;
  padding: 0.75em;
  background-color: #282c34;
  text-decoration: none;
  font-weight: normal;
  outline: none;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 20em;
  user-select: none;
}
button img {
  padding: 0 5% 0 0;
}
.actionText {
  text-decoration: underline;
  user-select: none;
}
.errorText {
  background-color: rgba(255, 0, 0, 0.3);
  color: red;
}
.appLoader {
  color: black;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.appVeil {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.appVeilTheme-3 {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.3);
}
.appVeilTheme-7 {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
}
.appVeilTheme-5 {
  background-color: rgba(0, 0, 0, 0.5);
}

.appVeilTheme-9 {
  background-color: rgba(0, 0, 0, 1);
}
.appVeilTheme-9b {
  opacity: 1;
  background-image: linear-gradient(#fff, #e6e6e6);
}
.appVeilTheme-10 {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
}
.appVeilTheme-11 {
  opacity: 1;
  background-color: #f7f7f7;
}
.noDisplay {
  display: none;
}

.modalToast {
  opacity: 0;
  position: fixed;
  left: 50%;
  width: 32.7em; /* STYLING EXPERIMENTAL */
  max-width: 97%; /* FOR MOBILE */
  transform: translate(-50%, 0%);
  z-index: 1500;
  user-select: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.modalToastText {
  height: 100%;
  display: flex;
  align-items: flex-start;
  max-width: 100%; /* FOR MOBILE */
  margin: auto;
  justify-content: space-between;
}

#modal-root {
  position: absolute;
  width: 100%;
  height: 100%;
}
.boxShadow1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.boxShadow2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.boxShadow3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.boxShadow4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.boxShadow5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

ul {
  padding-left: 1.2em;
}
ol {
  padding-left: 1.5em;
}
pre {
  margin: 1em 0;
}
code {
  background-color: #f3f3f3;
  padding: 1em;
}
blockquote {
  border-left: 0.5em solid silver;
  color: black;
  background-color: lightgray;
  font-style: italic;
  margin: 1em 0;
  padding: 0.5em 1em;
}

@media (min-width: 992px) {
  .icon_svg {
    width: 2em; /*calc(10px + 3vmin); */
    height: 2em; /* calc(10px + 3vmin); */
  }
  .spinnerDiv {
    padding-left: 2.205em;
  }
}
@media (pointer: fine) and (min-width: 992px) {
  /*BEG FONT SIZE SETTINGS*/
  .AppFontSizeSmall {
    font-size: calc(8px + 0.75vmin);
  }
  .AppFontSizeNormal {
    font-size: calc(10px + 1.5vmin);
  }
  .App input {
    font-size: calc(10px + 1.5vmin);
  }
  .App textarea {
    font-size: calc(10px + 1.5vmin);
  }

  .AppFontSizeBig1 {
    font-size: 1.5em;
  }
  .AppFontSizeBig2 {
    font-size: 2em;
  }
  /*END FONT SIZE SETTINGS*/
}

.anim_spinnerMove {
  animation: anim_spinnerMove 5s infinite ease-in-out;
}
@keyframes anim_spinnerMove {
  0% {
    transform: rotateY(0);
  }

  /* Finish changes by here */
  20% {
    transform: rotateY(360deg);
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    transform: rotateY(360deg);
  }
}

.anim_fadeInOut {
  animation: fadeInOut 2s ease-out 0s infinite normal forwards;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.anim_fadeInOut2 {
  animation: fadeInOut2 2s ease-out 0s infinite normal forwards;
}
@keyframes fadeInOut2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sThomeOuterWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sThomeHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em; /*NEEDS TO BE THE SAME AS IN GLOBAL HEADER*/
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}

@media only screen and (min-width: 920px) {
  .sThomeHeader {
    margin-left: 0;
    padding: 0 0.5em;
  }
}

.sTprodOuterWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTprodHeader {
  margin-left: 2.205em;
  display: flex;
  height: 2.25em; /*IN LINE WITH GLOBAL */
  visibility: hidden;
  z-index: -100;
}
.sTprodContentsWrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.sTprodContents1 {
  width: 100%;
  background-color: white;
}
.sTprodContents1Big {
  display: none;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  text-align: left;
  padding: 0.25em;
}
.sTprodContents1Small {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  text-align: left;
  padding: 0.25em;
}
.sTprodContents1SmallBig {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  text-align: left;
  padding: 0.25em;
  margin-top: 0.5em;
}
.sTprodContents1Inner {
  height: 100%;
}
.sTprodHeroImg {
  height: 100%;
  text-align: center;
}
.sTprodHeroImg img {
  max-height: 20em;
  max-width: 100%;
}
.sTprodContents2 {
  margin: 0.5em 0;
  display: flex;
  background-color: #f7f7f7;
}
.sTprodBackButton {
  z-index: 100;
  position: fixed;
  top: 3em;
  left: 0.5em;
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.sTorderActionFloat {
  position: sticky;
  bottom: 0em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: calc((100% - 13em) - 1em);
  bottom: 1em;
  width: 13em;
  padding: 0.5em;
  border-radius: 0.25em;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.24), 0 8px 12px rgba(0, 0, 0, 0.14);
}
.sTproductDetails {
  padding: 0.5em 0.25em 0 0.25em;
  text-align: left;
}

.sTprodFavsWrap {
  /* margin: auto; */
  width: 100%;
}
@media (min-width: 920px) {
  .sTprodBackButton {
    left: calc(2.205em + 0.5em);
  }
}
@media (min-width: 992px) {
  .sTprodHeader {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (orientation: landscape) {
  .sTprodHeroImg img {
    max-height: 24em;
  }
  .sTprodContentsWrap {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: white;
  }
  .sTprodContents1 {
    flex-basis: 50%; /*NEEDED FOR LAPTOP*/
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0 0.5em;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* background-color: #f7f7f7; */
  }
  .sTprodContents1Big {
    display: flex;
  }
  .sTprodContents1Small {
    display: none;
  }
  .sTprodContents2 {
    flex-basis: 50%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    /* padding: 0.5em; */
    height: auto;
    background-color: white;
    /* box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12); */
  }
  .sTproductDetails {
    padding: 2.25em 0.25em 0 0.25em;
  }
  .sTprodContents1SmallBig {
    margin-top: 0;
  }
  .sTprodBackButton {
    display: none;
  }
}

.sTaboutOuterWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTaboutHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em; /*IN LINE WITH GLOBAL */
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}
.sTaboutContentsWrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sTaboutContents2 {
  margin: 0.5em 0;
  height: calc(100% - 1em);
  display: flex;
}

.sTaboutTextWrap {
  margin: auto;
  padding: 0.25em;
  width: 100%;
  text-align: justify;
}

@media (min-width: 992px) {
  .sTaboutHeader {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (orientation: landscape) {
  .sTaboutContentsWrap {
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
  .sTaboutContents2 {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    margin: 0 auto;
    height: auto;
  }
}

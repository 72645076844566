.sTtrackOuterWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTtrackHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em;
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}
.sTtrackContentsWrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 3em;
}
.sTtrackContents1 {
  margin-top: 0.5em;
  width: 100%;
  /* height: calc(
    30% - 0.5em
  );  */
  /*NEEDS TO BE THE SAME AS BELOW ELSE A JUMP DURING SIGN IN*/
}
.sTtrackContents1b {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  margin-right: 0.25em;
}
.sTtrackItem1 {
  margin: 0 0.25em;
  padding: 0.25em;
  height: 100%;
  min-width: 11em;
  border-radius: 5px;
}
.sTtrackSmallCardContents {
  /* padding: 0.25em 0.25em 0 0.25em; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
}
.sTtrackIconWrap {
  display: flex;
  align-items: center;
  text-align: left;
}
.sTtrackCategoryText {
  width: 100%;
  text-align: left;
}
.sTtrackContents2 {
  height: 70%; /*calc(70% - 1em); */
  display: flex;
  flex-direction: column;
  padding: 0.5em 0;
  width: 42.1em; /*  STYLING EXPERIMENTAL */
  max-width: 100%; /* FOR MOBILE */
}
.sTtrackContents2_so {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.sTtrackCardWrap {
  /* width: 100%; */
  border-radius: 5px;
  margin: 0 0.25em 1em 0.25em;
}
.sTtrackCardHeader {
  width: 100%;
  padding: 0.25em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sTtrackDetails {
  width: 100%;
  padding: 0.25em;
  text-align: left;
}
.sTtrackFooterWrap {
  flex-basis: 3em; /*ALIGNED WITH FOOTER IN GLOBAL*/
  flex-shrink: 0;
  flex-grow: 0;
}
.sTtrackLoadingSummary {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .sTtrackHeader {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (orientation: landscape) {
  .sTtrackContentsWrap {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-top: 0.5em;
  }
  .sTtrackContents1 {
    flex-basis: 30%; /*NEEDED FOR LAPTOP*/
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0 auto;
    height: calc(100% - 0.5em);
    width: 100%;
  }
  .sTtrackContents1b {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: grid;
    grid-template-columns: repeat(2, 10em);
    grid-template-rows: repeat(auto-fill, minmax(5em, auto));
    grid-gap: 0.5em;
    margin-right: 0;
  }
  .sTtrackContents2 {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 auto;
    flex-basis: calc(
      60% - 2em
    ); /* sTorderContents1 is 30%, so we could set to 70) */
    max-width: 42.1em;
    height: auto;
    padding: 0;
  }
}
.sTtrack_anim_fadeIn_0300 {
  animation: sTtrackFadeIn 0.3s linear 0.3s 1 normal forwards;
}
.sTtrack_anim_fadeIn_0310 {
  animation: sTtrackFadeIn 0.3s linear 1s 1 normal forwards;
}
@keyframes sTtrackFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

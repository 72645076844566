.dBthemeBGcolor {
  color: black;
  background-color: #f7f7f7;
  /* background-color: aliceblue; */
}
.dBthemeBGcolor2 {
  background-color: #303d4c;
  color: white;
}
.dBthemeBGcolor2RGBA_09 {
  background-color: rgba(48, 61, 76, 0.9);
  color: white;
}
.dBthemeBGcolor3 {
  background-color: white;
  color: black;
}
.dBthemeBGcolor4 {
  background-color: #1d252e;
  color: white;
}
.dBthemeBGcolor6 {
  background-color: #45586e;
  color: white;
}
.dBthemeBGcolor7 {
  background-color: #e2e6e9;
  color: black;
}
.dBthemeToast {
  background-color: #627e9d;
  color: #fff;
}
.dBthemeShade3 {
  background-color: #627e9d;
  color: #fff;
}

.dBthemeAlert1 {
  background-color: darkred;
  color: #fff;
}
.dBthemeAlert1RGBA_09 {
  background-color: rgba(139, 0, 0, 0.9);
  color: white;
}

.dBthemeAlert1b {
  background-color: red;
  color: #fff;
}
.dBerror {
  color: darkred;
}
.dBthemeAlert2 {
  background-color: orange;
  color: black;
}
.dBthemeAlert3 {
  background-color: yellow;
  color: black;
}
.dBthemeAlert3c {
  color: yellow;
}
.dBthemeAlert4 {
  background-color: darkgreen;
  color: #fff;
}
.dBthemeAlert4b {
  background-color: green;
  color: #fff;
}
.dBthemeAlert4c {
  color: lightgreen;
}
.dBthemeDeselect {
  background-color: gray;
}
.dBthemeDeselectb {
  background-color: lightgray;
  color: black;
}

.appleScroll {
  -webkit-overflow-scrolling: touch;
}
.dBheader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 50;
  height: 2.25em; /* ALL PAGES HAVE ADOPTED THIS HEIGHT IN THEIR CSS */
}
.dBDashboardHeader {
  position: fixed;
  left: 0;
  margin-left: 2.205em; /*ALIGNED WITH NAVBAR WIDTH*/
  width: calc(100% - 2.205em);
  padding: 0 0.3em 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.25em; /* ALIGNED WITH HEADER IN PAGES AND MENU ICON HEIGHT */
  /* z-index: 100; */
  user-select: none;
}
.dBFooterWrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3em; /*ALIGNED WITH FOOTER IN PAGES*/
  user-select: none;
  font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
}
.dBFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.dBFooterItem {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  flex-basis: 33%;
  flex-shrink: 0;
  flex-grow: 0;
}
.dBFooterItem2 {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 0;
}

.dBnavBarHdrItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.205em; /* ALL PAGES HAVE ADOPTED THIS AS MARGIN LEFT IN THEIR CSS */
}
.dBpanelSide {
  position: fixed;
  left: -100%;
  bottom: 0;
  top: 0;
  z-index: 150;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  width: auto;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.dBnavBar {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  font-weight: bold;
  padding-top: 1em;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.dBnavMenuLeft1 {
  left: 0%;
}
.dBnavBarFooterToggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 3em; /*ALIGNED WITH PAGE FOOTERS*/
  flex-shrink: 0;
  flex-grow: 0;
}

.dBcontent_anim {
  position: absolute;
  /* left: 0; */
  width: 100%;
  height: 100%;
}
.dBnavBarGroup {
  display: flex;
  flex-direction: column;
  padding: 0.75em 0;
}
.dBnavBarItem {
  margin: 0em 0 0.25em 0;
  padding: 0.5em 0.5em;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 3.6em;
}

.dBnavBarText {
  padding: 0 0.5em 0 0.5em;
  white-space: nowrap;
  user-select: none;
}

.dBnavBarItem .dBtooltiptext {
  /* opacity: 0; */
  visibility: hidden;
  transform: translate(2em);
  text-align: center;
  padding: 0.5em 0.7em;
  border-radius: 5px;
  position: absolute;
  z-index: -100;
  white-space: nowrap;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.dBnavBarVeil {
  z-index: 125;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.dBveilOpen {
  display: block;
}
.dBmodal {
  position: fixed;
  top: 0;
  left: 50%;
  width: 32.7em; /* STYLING EXPERIMENTAL */
  max-width: 100%; /* FOR MOBILE */
  max-height: 100%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: left;
  z-index: 400;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transition: all 0.3s linear 0s;
  background-color: transparent;
  user-select: none;
  font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
}
.dBmodalHeader {
  padding: 0.5em;
  width: 100%;
  text-align: left;
  display: block;
}
.dBmodalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 0.25em;
  word-break: break-word;
  min-height: 8em;
}
.dBmodalFooter {
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.2);
  background-color: rgba(48, 61, 76, 0.9);
}
.dBmodalFooterItem {
  padding: 1.5em;
  font-weight: bold;
}
.dBbuttonWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dBbutton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 16em;
  max-width: 20em;
  margin: 0.25em auto;
  padding: 0.25em 0;
  border-radius: 5px;
}
.dBbuttonText {
  user-select: none;
  white-space: nowrap;
  padding: 0.5em 0.25em;
  font-weight: bold;
}
.dBbuttonIcon {
  padding: 0.25em 0.5em;
  margin: 0 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dBbuttonSample {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 16em;
  max-width: 20em;
  margin: 0.25em auto;
}
.editingOverlay {
  z-index: 110;
  padding: 0.25em;
  background-color: rgba(226, 93, 45, 0.85);
  border-radius: 5px;
}
input {
  border-radius: 5px;
}
.landscape {
  position: relative;
  margin: auto;
  max-width: 100%;
  width: 20em;
  height: 10em;
  border-radius: 0.25em;
  border-style: solid;
  border-color: black;
  border-width: 0.25em;
  overflow: hidden;
}

/* The screen (or content) of the device */
.landscape .content {
  width: 1366px;
  max-width: 100%;
  /* height: 800px; */
  height: 100%;
  overflow: hidden;
  border: none;
}

.portrait {
  position: relative;
  max-width: 100%;
  width: 10em;
  height: calc(10 * 1.89em);
  margin: auto;
  border: 0.25em black solid;
  border-radius: 0.25em;
  overflow: hidden;
}

/* The screen (or content) of the device */
.portrait .content {
  width: 360px;
  max-width: 100%;
  /* height: 640px; */
  height: 100%;
  background: white;
}

@media (pointer: coarse) {
}

@media only screen and (min-width: 920px) {
  .dBnavBar {
    padding-top: 0;
  }
  .dBheader {
    display: none;
  }
  .dBpanelSide {
    left: 0;
    width: 2.205em; /*THIS HAS BEEN ADOPTED AS MARGIN LEFT IN PAGE CSS's*/
  }

  .dBcontent_anim {
    left: 2.205em;
    width: calc(100% - 2.205em);
  }
  .dBcontent_anim2 {
    left: 8em;
    width: calc(100% - 8em);
  }
  .dBDashboardHeader2 {
    margin-left: 8em;
    width: calc(100% - 8em);
  }
  .dBnavMenuLeft1 {
    width: 8em;
  }
  .dBFooterWrap {
    margin-left: 2.205em; /*ALIGNED WITH NAVBAR WIDTH*/
    width: calc(100% - 2.205em);
  }
  .dBFooterWrap2 {
    margin-left: 8em;
    width: calc(100% - 8em);
  }
}

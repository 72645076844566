.sTcartOuterWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTcartHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em; /* IN LINE WITH GLOBAL */
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}
.sTcartContentsWrap {
  height: 100%;
  overflow: hidden;
}

.sTcartContents2 {
  height: calc(100% - 4em); /* 4em BECAUSE 1em for margin AND 3em FOR FOOTER */
  overflow: hidden;
  margin: 0.5em auto;
  width: 42.1em;
  max-width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.sTcartContents2b {
  /* height: 100%; */
  margin: auto;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.sTcartFooterWrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3em; /* ALIGNED WITH FOOTER IN GLOBAL AND ALSO DRIVING SOME ELEMENTS HERE */
  user-select: none;
}
.sTcartFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.sTcartFooterItem {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 0;
}
.sTcartCard {
  margin: 0 auto 0.5em auto;
  text-align: left;
  width: 100%;
}
@media (min-width: 992px) {
  .sTcartHeader {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (orientation: landscape) {
  .sTcartContentsWrap {
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
}

.sTcheckoutOuterWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTcheckoutHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em; /* IN LINE WITH GLOBAL */
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}
.sTcheckoutContentsWrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sTcheckoutContents2 {
  height: calc(100% - 1em); /* 4em BECAUSE 1em for margin AND 3em FOR FOOTER */
  overflow: auto;
  margin: 0.5em auto;
}

.sTiconWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sTcheckoutPaymentWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em auto 0 auto;
}
.sTcheckoutSelectWrap {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.25em;
  justify-content: space-between;
}
.sTcheckoutPaymentWrap form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sTcheckoutPaymentWrap input {
  background-color: white;
  max-width: 20em; /*500px*/
}
.sTcheckoutSelectWrap {
  max-width: 20em;
}
.sTcheckoutXenditWrap {
  width: 100%;
  max-width: 20em; /*500px*/
  background-color: white;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  font-weight: bold;
  overflow: hidden;
}
.sTcheckoutXenditCC {
  position: relative;
  left: 0;
  display: flex;
  align-items: center;
}
.sTcheckoutXenditIcon {
  display: none;
}
.sTcheckoutDivPosLeft {
  left: -100%;
}
.sTcheckoutFadedOut {
  opacity: 0;
}

.sTStripeElement {
  margin: 0 auto 0.5em auto;
  padding: 0.5em 0.25em;
  width: 100%;
  background-color: white;
  max-width: 20em; /* IN LINE WITH OTHER INPUT MAXWIDTH*/
}
@media (min-width: 340px) {
  .sTcheckoutXenditIcon {
    display: flex;
  }
}
@media (min-width: 992px) {
  .sTcheckoutHeader {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (orientation: landscape) {
}

.sTcheckout_anim_goInViewRight {
  animation: sTcheckout_goInViewRight 0.3s ease-out 0.3s 1 normal forwards;
}
@keyframes sTcheckout_goInViewRight {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
.sTcheckout_anim_fadeIn_0507 {
  animation: sTcheckout_fadeIn 0.5s ease-out 0.7s 1 normal forwards;
}
@keyframes sTcheckout_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sTcheckout_anim_fadeIn_0315 {
  animation: sTcheckout_fadeIn 0.3s ease-out 1.5s 1 normal forwards;
}
@keyframes sTcheckout_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sTcheckout_anim_fadeOutHidden_0300 {
  animation: sTcheckout_fadeOutHidden 0.5s ease-out 0s 1 normal forwards;
}
@keyframes sTcheckout_fadeOutHidden {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.sTcheckout_anim_fadeInVisible_0300 {
  animation: sTcheckout_fadeInVisible 0.5s ease-out 0s 1 normal forwards;
}
@keyframes sTcheckout_fadeInVisible {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
}

.sTfavsOuterWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTfavsHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em; /*IN LINE WITH GLOBAL */
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}
.sTfavsContentsWrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sTfavsContents2 {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  margin: 0 auto;
  width: 42.1em;
  max-width: 100%;
  padding: 0 0.25em;
}
.sTfavsContents2_so {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}
.sTfavsFavsWrap {
  margin: 0 auto;
  width: 100%;
}

.sTfavsFooterWrap {
  flex-basis: 3em; /*ALIGNED WITH FOOTER IN GLOBAL*/
  flex-shrink: 0;
  flex-grow: 0;
}

@media (min-width: 992px) {
  .sTfavsHeader {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (orientation: landscape) {
  .sTfavsContentsWrap {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-top: 0.5em;
  }
}

.sTorderOuterWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTorderHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em; /* IN LINE WITH GLOBAL */
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}
.sTorderContentsWrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sTorderContents1 {
  margin-top: 0.5em;
  /* height: calc(30% - 0.5em); */
  width: 100%;
}
.sTorderContents1b {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  margin: 0 0.25em;
}
.sTorderContents2 {
  padding: 0 0.25em;
  /* scroll-behavior: smooth; */
}
.sTorderItem1 {
  margin-left: 0.25em;
  height: 100%;
  display: flex; /* NEEDED FOR WHEN TEXT-TEMPLATE IN PORTRAIT */
  justify-content: center;
  align-items: flex-end;
  min-width: 12em; /* CHANGING THIS AFFECTS SRCSET DOWNLOADS */
}
.sTcategoryItemPic {
  min-height: 12em; /* CHANGING THIS AFFECTS SRCSET DOWNLOADS */
  height: 100%;
  background-color: white;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  user-select: none;
  color: transparent;
}
.sTcategoryText {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sTcategoryItemPicL {
  display: none;
}
.sTcategoryImageText {
  width: 100%;
  padding: 0.5em;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sTcategoryItemText {
  display: none;
}

.sTorderItemBarWrap1 {
  height: 100%;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  max-width: 100%;
}
.sTorderItemBarWrap2 {
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  max-width: 100%;
}

.sTitemItemPic {
  user-select: none;
  color: transparent;
  /** CHANGING 7em WILL AFFECT SRCSET SIZES */
  min-width: 7em; /* THIS AFFECTS sTorderItemBarContents AND sTfavIconBar AND sTCustomActionBtn */
  max-width: 7em; /* THIS AFFECTS sTorderItemBarContents AND sTfavIconBar AND sTCustomActionBtn */
  border-radius: 5px;
  text-align: center;
  min-height: 7em;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.sTorderItemActionWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sTorderItemBarContents {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 7em); /* 7EM BECAUSE OF sTitemItemPic */
}
.sTfavIconBar {
  display: flex;
  justify-content: center;
  width: 7em; /* 7EM BECAUSE OF sTitemItemPic */
}
.sTorderItemActionBtn {
  display: flex;
  align-items: center;
  padding: 0 0.25em;
  flex-direction: column;
}

.sTorderItemActionBtn select {
  opacity: 0;
  position: relative;
  top: -1em;
}

.sTorderCustomInputBtnWrap {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25em 0;
  font-family: Arial, Helvetica, sans-serif;
}

.sTorderCustomInputBtn {
  overflow: hidden;
  word-break: break-word;
}
.sTCustomActionBtn {
  display: flex;
  min-width: 7em; /* 7EM BECAUSE OF sTitemItemPic */
  justify-content: center;
  align-items: center;
  margin: 0 0.25em 0.25em 0;
}
.sTorderFooterWrap {
  flex-basis: 3em; /*ALIGNED WITH FOOTER IN GLOBAL*/
  flex-shrink: 0;
  flex-grow: 0;
}
.sTorderItemBar {
  height: auto;
  margin: 0.5em auto;
  width: 100%;
  background-color: white;
  transition: all 0.2s ease 0s;
  text-align: left;
  color: black;
  border-radius: 5px;
  padding: 0.5em;
}
.sTorderItemBar2 {
  height: auto;
  min-height: 6em;
  margin: 0.5em auto;
  width: 100%;
  background-color: white;
  transition: all 0.2s ease 0s;
  text-align: left;
  color: black;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.24), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
}
.sTorderItemTag {
  /* margin: 0.25em; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sTcategoryBar {
  display: none;
}

.sTcatBarImg {
  overflow: hidden;
  transition: all 0.5s ease 0s;
}
.sTcatBarImg img {
  transition: all 0.5s ease 0s;
}
/* .sTcatBarImg img:hover {
  transform: scale(1.2);
} */
@media (min-width: 991px) {
  .sTorderHeader {
    margin-left: 0;
  }
}

@media (min-width: 991px) and (orientation: landscape) {
  .sTorderContentsWrap {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-top: 0.5em;
  }
  .sTorderContents1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1em;
    height: 100%;
    width: calc(100% - 2em);
    max-width: 25%; /** CHANGING THIS AFFECTS SRCSET SIZES */
  }
  .sTorderContents1b {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: block;
    margin: 0;
  }
  .sTcategoryItemPic {
    display: none;
  }
  .sTcategoryItemPicL {
    width: 100%;
    height: 100%;
    user-select: none;
    color: transparent;
    display: block;
    text-align: center;
  }
  .sTcategoryItemPicL img {
    max-width: 100%;
    min-width: 100%;
  }
  .sTcategoryItemText {
    display: block;
  }
  .sTorderItem1 {
    display: none;
    height: auto;
    margin-bottom: 1em;
    text-align: center;
    text-transform: capitalize;
    border-radius: 5px;
  }
  .sTorderContents2 {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 auto;
    flex-basis: calc(
      60% - 2em
    ); /* sTorderContents1 is 30%, so we could set to 70) */
  }
  .sTcategoryBar {
    display: block;
    margin: 0 0.5em;
  }
  .sTorderItemBar {
    margin: 0 auto 0.5em auto;
  }
  .sTorderItemBar2 {
    margin: 0 auto 0.5em auto;
  }
}
/*FADE IN*/
.dBorders_anim_fadeIn_0100 {
  animation: dBorders_fadeIn 0.1s linear 0s 1 normal forwards;
}
.dBorders_anim_fadeIn_0103 {
  animation: dBorders_fadeIn 0.1s linear 0.3s 1 normal forwards;
}

@keyframes dBorders_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* VISIBILITY SNAP */
.dBorders_anim_description_in {
  animation: dBorders_description_in 0.5s linear 0.1s 1 normal forwards;
}
@keyframes dBorders_description_in {
  0% {
    opacity: 0;
    height: auto;
  }
  100% {
    opacity: 1;
    height: auto;
  }
}

/* MOVE LEFT */
.dBorders_anim_moveLeft_0300 {
  animation: dBorders_moveLeft 0.1s linear 0s 1 normal forwards;
}

@keyframes dBorders_moveLeft {
  0% {
    transform: translate(0.75em);
  }
  100% {
    transform: translate(0em);
  }
}

/* MOVE LEFT */
.dBorders_anim_moveRight_0300 {
  animation: dBorders_moveRight 0.1s linear 0s 1 normal forwards;
}
.dBorders_anim_moveRight_0306 {
  animation: dBorders_moveRight 0.1s linear 0.6s 1 normal forwards;
}

@keyframes dBorders_moveRight {
  0% {
    transform: translate(-0.75em);
  }
  100% {
    transform: translate(0em);
  }
}

.sTpaymentAuthOuterWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;
}
.sTpaymentAuthHeader {
  margin-left: 2.205em;
  display: flex;
  flex-basis: 2.25em; /* IN LINE WITH GLOBAL */
  flex-shrink: 0;
  flex-grow: 0;
  visibility: hidden;
  z-index: -100;
}
.sTpaymentAuthContentsWrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.sTpaymentAuthContents2 {
  height: calc(100% - 1em);
  overflow: hidden;
  margin: 0.5em auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sTpaymentAuthFadedOut {
  opacity: 0;
}
@media (min-width: 992px) {
  .sTpaymentAuthHeader {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (orientation: landscape) {
  .sTpaymentAuthContents2 {
    height: calc(
      100% - 1em
    ); /* 4em BECAUSE 1em for margin AND 3em FOR FOOTER */
    overflow: hidden;
    margin: 0.5em auto;
    flex-basis: calc(
      60% - 2em
    ); /* sTorderContents1 is 30%, so we could set to 70) */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
.sTpaymentAuth_anim_fadeIn_0305 {
  animation: sTpaymentAuth_fadeIn 0.3s ease-out 0.5s 1 normal forwards;
}
@keyframes sTpaymentAuth_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
